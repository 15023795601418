<template>
  <div class="page-load"></div>
  <Hero
    heroTitle="Hire excellent African <br class='hidden xl:inline-block'>
    <span class='text-[#FE0D73] founders font-semibold'>Sales & Marketing</span> talent 
      and reduce overhead by 70%"
    heroDesc="We help startups and larger companies find, interview, and hire great pre-vetted Sales & Marketing talent from Africa for your teams."
    heroBtnText="Get Started"
    heroImg="sales-man.png"
  />

  <Customers />

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">
          Why hire Sales & Marketing Talent from Africa?
        </h2>

        <HiringSupport />
      </div>
    </div>
  </div>

  <CanHelp :helps="helps" />
  <JobTitles heading="Sales & Marketing Job Titles" :titles="titles" />
  <CreateAccount />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Hero from '@/components/partials/Hero.vue';
import HiringSupport from '@/components/partials/HiringSupport.vue';
import Customers from '@/components/partials/Customers.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import JobTitles from '@/components/partials/JobTitles.vue';
import CanHelp from '@/components/partials/CanHelp.vue';

const titles = ref([
  'Content Writer',
  'Community Manager',
  'Copywriter',
  'Digital Marketing Manager',
  'Sales Reps (SDRs & BDRs)',
  'Social Media Manager',
  'Email Marketing Specialist',
  'Marketing Assistant',
  'Programmatic Marketing',
  'Growth Marketing Manager',
  'Outbound Sales Rep',
  'Inbound Sales Representative',
]);

const helps = ref([
  {
    title: 'Save 30-70% on salaries',
    description: 'We offer lower-cost, motivated candidates.',
  },
  {
    title: 'Hire quickly — 10x Faster',
    description: 'Typically you can hire in under 14 days.',
  },
  {
    title: 'Find the best teammates for you',
    description: ' op 1% Sales &amp; Marketing talents that help build strong businesses.',
  },
  {
    title: 'Save time with pre-vetted candidates',
    description: 'English speaking. Highly skilled. Sales &amp; Marketing  experience.',
  },
]);
onMounted(() => {
  document.querySelector('.page-load')?.scrollIntoView();
});
</script>
